import Model from './Model'
import { each } from 'lodash'
// import Avatar from './Avatar'

export default class Venue extends Model {
  resource() {
    return 'superadmin/venues'
  }

  venueHours() {
    let venueHrs = [[], [], [], [], [], [], []]
    each(this.venue_hours, (v, i) => {
      venueHrs[v.day].push({ from: v.from, to: v.to })
    })
    return venueHrs
  }

  coverImage() {
    if (this.venue_photos && this.venue_photos[0]) {
      return this.venue_photos[0].url
    }
    return ''
  }
}
