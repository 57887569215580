import Venue from '@/models/Venue'
import Api from '@/services/api'
import qs from 'qs'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    venueDetails: null,
    requestStatus: '',
    requestMsg: ''
  },

  mutations: {
    setVenueList(state, venues) {
      each(venues, venue => {
        const exist = find(state.list, { id: venue.id })
        if (!exist) {
          state.list.push(new Venue(venue))
        }
      })
    },
    clearVenueDetails(state) {
      state.venueDetails = null
    },
    setVenueDetails(state, venue) {
      state.venueDetails = new Venue(venue.data)
    },
    clearVenueList(state) {
      state.list = []
    },

    setVenueListMeta(state, meta) {
      state.listMeta = meta
    },

    setRequestStatus(state, payload) {
      state.requestMsg = payload.msg
      state.requestStatus = payload.status
    },

    removeVenueFromList(state, id) {
      const venueIndex = find(state.list, { id: id })
      state.list.splice(venueIndex, 1)
    }
  },

  actions: {
    async getVenues({ commit }, params) {
      const query = Venue.page(params.page || 1)
      if (params.search) {
        query.where('search', params.search)
      }
      if (params.sort) {
        query.orderBy(params.sort)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setVenueList', res.data)
      commit('setVenueListMeta', res.meta)
    },

    async deleteVenue({ commit }, id) {
      await Api.delete(`superadmin/venues/${id}`)
        .then(response => {
          let payload = { msg: 'Venue Deleted.', status: 'error' }
          commit('setRequestStatus', payload)
          commit('removeVenueFromList', id)
        })
        .catch(error => {
          let payload = {
            msg: error.response.response.data.message,
            status: 'error'
          }
          commit('setRequestStatus', payload)
        })
    },

    async getVenueDetails({ commit }, id) {
      const venue = await Venue.find(id)
      commit('setVenueDetails', venue)
    },

    async saveVenue({ commit }, formData) {
      const venue = await new Venue(formData)
        .save()
        .then(data => {
          return data
        })
        .catch(err => {
          return err.response.data
        })

      return venue
    },

    async updateVenue({ commit }, formData) {
      await Api.put(`superadmin/venues/${formData.id}`, formData)
        .then(({ data }) => {
          commit('setVenueDetails', data)
        })
        .catch(err => {
          commit('setErrors', err.response.data.errors)
        })
    },

    async exportToExcel({ commit }, p) {
      const query = Api

      const file = await query.get('/superadmin/export/venues', {
        responseType: 'blob',
        params: {
          filter: {
            search: p.search
          },
          sort: p.sort
        },
        paramsSerializer: params => {
          return qs.stringify(params, { encode: false })
        }
      })
      return file
    }
  }
}
