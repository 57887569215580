import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#4ab7ee',
        secondary: '#5b3e97',
        accent: '#8c9eff',
        error: '#c21e51',
        grey: {
          base: '#9E9E9E',
          lighten3: '#f1f3f4'
        }
      }
    }
  }
})
