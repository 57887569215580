import VenueType from '@/models/VenueType'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    venueTypeDetails: null
  },

  mutations: {
    setVenueTypeList(state, venueTypes) {
      each(venueTypes, venueType => {
        const exist = find(state.list, { id: venueType.id })
        if (!exist) {
          state.list.push(new VenueType(venueType))
        }
      })
    },

    clearVenueTypeList(state) {
      state.list = []
    },

    setVenueTypeListMeta(state, meta) {
      state.listMeta = meta
    }
  },

  actions: {
    async getVenueTypes({ commit }, params) {
      const query = VenueType.page(params.page || 1)
      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setVenueTypeList', res.data)
      commit('setVenueTypeListMeta', res.meta)
    }
  }
}
