import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import venue from './modules/venue'
import venueType from './modules/venueType'
import user from './modules/user'
import notification from './modules/notification'
import report from './modules/report'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    venue,
    venueType,
    user,
    notification,
    report
  },
  state: {
    controls: {
      showDrawer: true
    }
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    }
  }
})
