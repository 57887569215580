<template>
  <div class="text-field-label">
    <slot></slot>
    <span class="error--text" v-if="required">*</span>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>
