import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import FormLabel from '@/components/global/form-label'

Vue.use(Router)

// register global components
Vue.component('form-label', FormLabel)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'venues' },
      component: () => import('@/views/Home'),
      children: [
        // {
        //   path: 'inbox',
        //   name: 'inbox',
        //   component: () =>
        //     import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox')
        // },
        {
          path: 'venues',
          name: 'venues',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Venues')
        },
        {
          path: 'venues/new',
          name: 'venues.new',
          component: () => import('@/views/Home/Venues/New')
        },
        {
          path: 'venues/:id/edit',
          name: 'venues.edit',
          component: () => import('@/views/Home/Venues/Edit')
        },
        {
          path: 'venues/:id',
          name: 'venues.view',
          component: () => import('@/views/Home/Venues/Details')
        },

        // {
        //   path: 'venue-types',
        //   name: 'venue-types',
        //   component: () =>
        //     import(/* webpackChunkName: "users" */ '@/views/Home/VenueTypes')
        // },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users')
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            )
        }
        // {
        //   path: 'content',
        //   name: 'content',
        //   component: () =>
        //     import(/* webpackChunkName: "content" */ '@/views/Home/Content')
        // },
        // {
        //   path: 'notification',
        //   name: 'notification',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
        //     )
        // },
        // {
        //   path: 'settings',
        //   name: 'settings',
        //   component: () =>
        //     import(/* webpackChunkName: "settings" */ '@/views/Home/Settings')
        // },
        // {
        //   path: 'reports',
        //   name: 'reports',
        //   component: () =>
        //     import(/* webpackChunkName: "reports" */ '@/views/Home/Reports')
        // }
      ]
    }
  ]
})
